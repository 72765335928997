<script>
import { Bar } from "vue-chartjs";

export default {
  name: "BarChart",
  extends: Bar,
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: [{ data: this.data, backgroundColor: this.backgroundColor }]
      };
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  props: ["options", "data", "backgroundColor", "labels"]
};
</script>
